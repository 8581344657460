import {uuid4} from "@capacitor/core/dist/esm/util";
import {PLUS_STATUS, ProductionDailyUserBonus, ProductionUserBonus} from "@/domain/model/productionBonus/bonus.model";
import {User} from "@/domain/model/user/user.model";
import {BonusMaterialRange, emptyBonusMaterialRange} from "@/domain/model/bonusMaterial/bonusMaterial.model";
import {emptyCreateBonusMaterialRanges} from "@/domain/command/bonusMaterial/bonusMaterial.command";

export interface CreateProductionUserBonus {
    id: string;
    userId: string;
    materialId: string;
    amountExpected: number;
    date: number;
}

export interface CreateProductionDailyUserBonus {
    id: string;
    userId: string;
    date: number;
    status: PLUS_STATUS;
}

export interface SetProductionDailyUserBonus {
    id: string;
    userId: string;
    date: number;
    status: PLUS_STATUS;
}

export interface SetProductionUserBonus {
    id: string;
    userId: string;
    date: number;
    materialId: string;
    amount: number;
    status: PLUS_STATUS;
    amountExpected: number;
    bonusMaterialRange: BonusMaterialRange | null;
}


export function createBonusFromUserId(userId: string): CreateProductionUserBonus {
    return {
        id: uuid4(),
        userId: userId,
        materialId: '',
        amountExpected: 0,
        date: 0,
    }
}

export function setBonusFromUserId(userId: string): SetProductionUserBonus {
    return {
        id: uuid4(),
        userId: userId,
        date: 0,
        amount: 0,
        materialId: '',
        status: 'not_achieved',
        amountExpected: 0,
        bonusMaterialRange: emptyBonusMaterialRange(),
    }
}

export function setBonusFromProductionUserBonus(bonus: ProductionUserBonus): SetProductionUserBonus {
    return {
        id: !bonus ? '' : bonus.id,
        userId: !bonus ? '' : bonus.user.id,
        date: !bonus ? 0 : bonus.date,
        materialId: !bonus ? '' : bonus.material.id,
        amount: !bonus ? 0 : bonus.amount,
        status: !bonus ? "not_achieved" : bonus.status,
        amountExpected: !bonus ? 0 : bonus.amountExpected,
        bonusMaterialRange: !bonus ? emptyBonusMaterialRange() : bonus.bonusMaterialRange,
    }
}

export function setBonusFromEntityOrEmpty(bonus: ProductionUserBonus, userId: string): SetProductionUserBonus {
    if (!bonus)
        return setBonusFromUserId(userId);
    return setBonusFromProductionUserBonus(bonus);
}

export function entityMaterialIsDefined(entity: ProductionUserBonus): boolean {
    return Boolean(entity) && Boolean(entity.material);
}

export function commandEqualToEntity(command: SetProductionUserBonus, entity: ProductionUserBonus): boolean {
    const firstCondition = Boolean(entity) && Boolean(entity.id) && Boolean(command) && Boolean(command.id)
        && command.id === entity.id && command.amount === entity.amount
        && command.amountExpected === entity.amountExpected && command.status === entity.status;
    const secondCondition = entityMaterialIsDefined(entity);
    if (secondCondition)
        return firstCondition && command.materialId === entity.material.id;
    else
        return firstCondition && !command.materialId;
}

export function createDailyBonusCommandFromUserId(userId: string, selectedDay: number): CreateProductionDailyUserBonus {
    return {
        id: uuid4(),
        userId: userId,
        date: selectedDay,
        status: 'not_achieved',
    }

}

export function setDailyBonusCommandFromParams(bonus: ProductionDailyUserBonus, newStatus: PLUS_STATUS): SetProductionDailyUserBonus {
    return {
        id: bonus.id,
        userId: bonus.user.id,
        date: bonus.date,
        status: newStatus,
    }
}

