
import {computed, defineComponent, h, ref, watch} from "vue";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid, IonHeader, IonIcon, IonLabel,
  IonPage,
  IonRow, IonSearchbar, IonTitle, IonToggle, IonToolbar,
  isPlatform, onIonViewWillEnter, onIonViewWillLeave
} from "@ionic/vue";
import {useStore} from "vuex";
import {getShift, Shift, User} from "@/domain/model/user/user.model";
import {useUserApi} from "@/domain/service/api/use-user-api";
import {useBonusApi} from "@/domain/service/api/bonus.api";
import moment from "moment";
import {ColumnOrder} from "@/domain/model/view/columnOrder.model";
import {NDatePicker, NTabPane, NTabs, NSpace, NDataTable, NSelect, NInputNumber, NButton, NInput} from "naive-ui";
import _, {cloneDeep, map} from "lodash";
import {ProductionDailyUserBonus, ProductionUserBonus} from "@/domain/model/productionBonus/bonus.model";
import {loading} from "@/app/service/ionic/loading";
import {
  createBonusFromUserId,
  createDailyBonusCommandFromUserId,
  CreateProductionDailyUserBonus,
  CreateProductionUserBonus,
  setBonusFromProductionUserBonus,
  setDailyBonusCommandFromParams,
  SetProductionUserBonus
} from "@/domain/command/bonus/bonus.command";
import {Material} from "@/domain/model/material/material.model";
import {BonusMaterial, BonusMaterialRange} from "@/domain/model/bonusMaterial/bonusMaterial.model";
import {UpdateUser, updateUserByEntity} from "@/domain/command/user/user.command";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {useBonusMaterialApi} from "@/domain/service/api/bonusMaterial.api";
import {useViewStateApi} from "@/domain/service/api/viewState.api";
import {menuOutline} from "ionicons/icons";
import DinaLabel from '@/app/component/labelForTable/dina-label.vue';
import {
  CreateBonusMaterial, createBonusMaterialFromUpdate, defaultCreateBonusMaterialRanges,
  emptyCreateBonusMaterialRanges, emptyUpdateBonusMaterial, UpdateBonusMaterial
} from '@/domain/command/bonusMaterial/bonusMaterial.command';

export default defineComponent({
  name: "ProductionBonusDailyListPage",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonSearchbar,
    NDatePicker,
    NTabs,
    NTabPane,
    NSpace,
    NDataTable,
    IonIcon,
  },
  setup() {
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const store = useStore();
    const menuIsShowing = computed<boolean>(() => store.getters.getShowMenu);
    const onShowMenu = async () => {
      await useViewStateApi().showMenuToTrue();
    }

    const searchWord = ref<string>('');
    const shifts = ref<Shift[]>(['morning', 'afternoon', 'night', 'central', 'sick_leave']);
    const sortTypeColor = ref<string>('');
    const selectedDay = ref(new Date().getTime());
    const todayDate = computed(() => moment(selectedDay.value).format('DD/MM/YYYY'));
    const data = ref<any>([]);
    const selectedShift = ref<string>(shifts.value[0]);

    const loadingStatus = ref<boolean>(false);
    const allBonusMaterial = computed<ProductionUserBonus[]>(() => store.getters.allBonus());
    const allMaterials = computed<Material[]>(() => store.getters.allMaterial);

    const columnOrder = ref<ColumnOrder>({order: '', key: ''});

    const searchUser = (user: User, word: string): boolean => user.name?.toLowerCase().includes(word.toLowerCase()) ||
        user.username?.toLowerCase().includes(word.toLowerCase())
        || user.surname?.toLowerCase().includes(word.toLowerCase());

    const filterUser = (users: User[], word: string): User[] => _.filter(users, (user: User) => searchUser(user, word));
    const allUsers = computed(() => filterUser(store.getters.allUsers, searchWord.value));
    const allDailyBonuses = computed(() => {
      return store.getters.allDailyBonuses()
    });

    const shiftUsers = ref<User[]>(allUsers.value.filter((user: User) => user.shift === selectedShift.value));

    const onUpdateUserDailyBonusIncome = async (row: any, newValue: number) => {
      const user: User = store.getters.userById(row.id);
      const setCommand: UpdateUser = updateUserByEntity(user);
      setCommand.dailyBonusIncome = newValue;
      await useUserApi().updateUser(setCommand);
    };
    const onUpdateDailyBonus = async (row: any, event: any) => {
      let bonusCommand: CreateProductionDailyUserBonus | null = null;
      if (!row.plus) {
        bonusCommand = createDailyBonusCommandFromUserId(row.id, new Date(selectedDay.value).setHours(new Date(selectedDay.value).getHours() + 1) / 1000);
        await useBonusApi().createDailyBonus(bonusCommand);
      }
      const bonus: ProductionDailyUserBonus = store.getters.dailyUserBonusById(!bonusCommand ? row.plus.id : bonusCommand.id);
      await useBonusApi().setDailyBonus(setDailyBonusCommandFromParams(bonus, event ? 'achieved' : 'not_achieved'))
    };
    const onUpdateProdBonus = async (row: any, newValue: string) => {
      let productionUserBonus: ProductionUserBonus = store.getters.bonusByUserIdAndDateRange(row.id, new Date(selectedDay.value).setHours(0, 0, 0), new Date(selectedDay.value).setHours(23, 59, 59));
      const material: Material = store.getters.materialByRef(newValue);
      if (!productionUserBonus) {
        const command: CreateProductionUserBonus = createBonusFromUserId(row.id)
        command.materialId = material.id;
        command.date = Math.trunc(selectedDay.value / 1000);
        await useBonusApi().createBonus(command);
        productionUserBonus = store.getters.bonusById(command.id);
      }
      const setCommand: SetProductionUserBonus = setBonusFromProductionUserBonus(productionUserBonus);
      setCommand.materialId = material.id
      await useBonusApi().setBonus(setCommand);
    }

    const onUpdateAmountAndRange = async (row: any, newAmount: number, newKit: string) => {
      let productionUserBonus: ProductionUserBonus = store.getters.bonusByUserIdAndDateRange(row.id, new Date(selectedDay.value).setHours(0, 0, 0), new Date(selectedDay.value).setHours(23, 59, 59));
      const materialRef = !newKit ? row.oldKitName : newKit;
      const material: Material = store.getters.materialByRef(materialRef);
      if (!productionUserBonus) {
        const command: CreateProductionUserBonus = createBonusFromUserId(row.id)
        command.materialId = material.id;
        command.date = Math.trunc(selectedDay.value / 1000);
        await useBonusApi().createBonus(command);
        productionUserBonus = store.getters.bonusById(command.id);
      }
      const setCommand: SetProductionUserBonus = setBonusFromProductionUserBonus(productionUserBonus);
      setCommand.materialId = material.id;
      setCommand.amount = newAmount;
      setCommand.status = newAmount > 0 ? 'achieved' : 'not_achieved';
      setCommand.bonusMaterialRange = store.getters.bonusMaterialByMaterialRef(material.ref).ranges.find((rangeNode: BonusMaterialRange) =>
          newAmount >= rangeNode.min && newAmount <= rangeNode.max);
      await useBonusApi().setBonus(setCommand);
    }

    const shouldRenderAllTable = ref<boolean>(true);
    const onSaveAll = async (row: any) => {
      loadingStatus.value = true;
      await loading().exec('Guardando...', async () => {
        shouldRenderAllTable.value = false;
        if (row.newIncome && row.oldIncome !== row.newIncome)
          await onUpdateUserDailyBonusIncome(row, row.newIncome);
        if (row.oldPlusSelected !== row.newPlusSelected && row.newPlusSelected !== null)
          await onUpdateDailyBonus(row, row.newPlusSelected);
        if (row.oldKitName !== row.newKitName && row.newKitName)
          await onUpdateProdBonus(row, row.newKitName)
        if (row.newAmount !== row.amount && row.newAmount !== null)
          await onUpdateAmountAndRange(row, row.newAmount, row.newKitName);
        row.enabledButton = false;
        loadingStatus.value = false;
      });
    }
    const type = {type: 'Daily'};
    const replaceData = (shiftUsersLocal: User[], dailyBonusesLocal: ProductionDailyUserBonus[], allProductionUserBonus: ProductionUserBonus[]) => {
      if (!shouldRenderAllTable.value)
        return;
      let index = -1;
      const tableData = map(shiftUsersLocal, (user: User) => {

        const plus: ProductionDailyUserBonus | undefined = dailyBonusesLocal.find((dailyBonusLocal: ProductionDailyUserBonus) => {
              return dailyBonusLocal.user.id === user.id && dailyBonusLocal.date * 1000 >= new Date(selectedDay.value).setHours(0, 0, 0) &&
                  dailyBonusLocal.date * 1000 <= new Date(selectedDay.value).setHours(23, 59, 59)
            }
        );
        const productionUserBonus: ProductionUserBonus | undefined = allProductionUserBonus.find((productionUserBonusNode: ProductionUserBonus) => {
          return productionUserBonusNode.user.id === user.id && productionUserBonusNode.date * 1000 >= new Date(selectedDay.value).setHours(0, 0, 0) &&
              productionUserBonusNode.date * 1000 <= new Date(selectedDay.value).setHours(23, 59, 59)
        });

        let bonusMaterial: BonusMaterial | null = null;
        if (productionUserBonus) {
          bonusMaterial = store.getters.allBonusMaterial.find((bonusMaterial: BonusMaterial) =>
              bonusMaterial.ref === productionUserBonus.material.ref);
        }
        let isSelected = false;
        if (plus && plus.status === 'achieved')
          isSelected = true;
        index++;
        return {
          index,
          key: uuid4(),
          id: user.id,
          name: !user.name ? '' : user.name,
          surname: user.surname,
          isSelected,
          plus: !plus ? null : cloneDeep(plus),
          dailyBonusIncome: user.dailyBonusIncome,

          productionUserBonus: !productionUserBonus ? null : cloneDeep(productionUserBonus),
          kit: !productionUserBonus ? null : productionUserBonus.material.ref,
          options: allMaterials.value.filter((material: Material) => material.type === 'production').map((material: Material) => {
            return {label: material.ref, value: material.ref}
          }),
          refSelected: productionUserBonus,
          bonusMaterial: !bonusMaterial ? null : bonusMaterial,
          rangeOptions: !bonusMaterial ? [{
            label: "No conseguido",
            value: "No conseguido"
          }] : [...bonusMaterial.ranges.map((range: BonusMaterialRange) => {
            return {label: range.name, value: range.name}
          }), {label: "No conseguido", value: "No conseguido"}],
          bonusMaterialId: !bonusMaterial ? null : bonusMaterial.id,

          enabledButton: false,

          oldIncome: user.dailyBonusIncome,
          oldPlusSelected: isSelected,
          oldKitName: !productionUserBonus ? null : productionUserBonus.material.ref,
          oldRangeName: !productionUserBonus ? null : productionUserBonus.bonusMaterialRange.name,

          amount: !productionUserBonus ? null : productionUserBonus.amount,
          newAmount: null,
          rangeByAmount: !productionUserBonus ? 'Rango por defecto' : productionUserBonus.bonusMaterialRange.name,

          newIncome: 0,
          newPlusSelected: null,
          newKitName: '',
          newRangeName: '',
        };
      });
      data.value = tableData;
    };
    const onChangeShift = (shift: Shift) => {
      selectedShift.value = shift;
    }

    watch(selectedShift, (newSelectedShift) => {
      shiftUsers.value = allUsers.value.filter((user: User) => user.shift === newSelectedShift);
      shouldRenderAllTable.value = true;
      replaceData(shiftUsers.value, allDailyBonuses.value, allBonusMaterial.value);
    });
    watch(allUsers, (newAllUsers) => {
      shiftUsers.value = newAllUsers.filter((user: User) => user.shift === selectedShift.value);
      replaceData(shiftUsers.value, allDailyBonuses.value, allBonusMaterial.value);
    });
    watch(allBonusMaterial, (newAllBonusMaterial) => {
      shiftUsers.value = allUsers.value.filter((user: User) => user.shift === selectedShift.value);
      replaceData(shiftUsers.value, allDailyBonuses.value, newAllBonusMaterial);
    });
    watch(allDailyBonuses, (newAllDailyBonuses) => {
      replaceData(shiftUsers.value, newAllDailyBonuses, allBonusMaterial.value);
    }, {immediate: true});
    watch(selectedDay, async (newSelectedDay) => {
      await useBonusApi().fetchAllDailyByDate(new Date(newSelectedDay).setHours(new Date(newSelectedDay).getHours() + 1) / 1000)
      shouldRenderAllTable.value = true;
      replaceData(shiftUsers.value, allDailyBonuses.value, allBonusMaterial.value);
    });

    const calculateNewRange = async (rowIndex: number, oldRef: string, newRef: string, amount: number) => {
      const refName = !oldRef ? newRef : oldRef;
      let bonusMaterial: BonusMaterial | undefined = store.getters.bonusMaterialByMaterialRef(refName);
      if (!bonusMaterial) {
        const updateCommand: UpdateBonusMaterial = emptyUpdateBonusMaterial();
        updateCommand.ref = refName;
        const createCommand: CreateBonusMaterial = createBonusMaterialFromUpdate(updateCommand);
        await useBonusMaterialApi().createBonusMaterial(createCommand);
        await useBonusMaterialApi().updateBonusMaterial(updateCommand);
      }
      bonusMaterial = store.getters.bonusMaterialByMaterialRef(refName);
      const range: BonusMaterialRange | undefined = bonusMaterial?.ranges.find((rangeNode: BonusMaterialRange) => amount >= rangeNode.min && amount <= rangeNode.max);
      data.value[rowIndex].rangeByAmount = !range ? 'Rango por defecto' : range.name;
    }

    const updateRow = (row: any) => {
      if (row.newKitName && row.oldKitName !== row.newKitName)
        data.value[row.index].refSelected = true;
    }

    onIonViewWillEnter(async () => {
      await useViewStateApi().showMenuToFalse();
      shouldRenderAllTable.value = true;
      loadingStatus.value = true;
      const today = new Date().setUTCHours(0, 0, 0, 0);
      await useBonusMaterialApi().fetchAll();
      await useMaterialApi().fetchAll();
      await useUserApi().fetchAll();
      await useBonusApi().fetchAllDailyByDate(today / 1000);
      await useBonusApi().fetchAll();
      await useBonusMaterialApi().fetchAllMaterialByDate(today / 1000);
      loadingStatus.value = false;
    })

    onIonViewWillLeave(async () => {
      await useViewStateApi().showMenuToTrue();
    })
    return {
      menuIsShowing,
      menuOutline,
      data,
      columns: [
        {
          title: "Nombre",
          width: "70px",
          key: "name",
          sorter: 'default',
        },
        {
          title: "Apellidos",
          width: "70px",
          key: "surname",
          sorter: 'default',
        },
        {
          title: "Dinero por plus diario",
          width: "100px",
          key: "dailyBonusIncome",
          sorter: 'default',
          render(row: any) {
            return h(
                NInputNumber,
                {
                  defaultValue: Number(row.dailyBonusIncome),
                  onUpdateValue: (value: any) => {
                    row.newIncome = value;
                    row.enabledButton = true;
                  }
                },
                {suffix: () => '€',}
            );
          },
        },
        {
          title: "Plus diario",
          width: "60px",
          key: "plus",
          sorter: 'default',
          render(row: any) {
            const isSelected = row.isSelected;
            return h(
                IonToggle,
                {
                  checked: isSelected,
                  onIonChange: (event: any) => {
                    row.newPlusSelected = event.detail.checked;
                    row.enabledButton = true;
                  }
                },
            );
          },
        },
        {
          title: "Kit",
          width: "100px",
          key: "kit",
          sorter: 'default',
          render(row: any) {
            return h(
                NSelect,
                {
                  filterable: true,
                  options: row.options,
                  defaultValue: row.kit,
                  onUpdateValue: (value: string) => {
                    row.enabledButton = true;
                    row.bonusMaterial = store.getters.allBonusMaterial.find((bonusMaterial: BonusMaterial) =>
                        bonusMaterial.ref === value);
                    row.bonusMaterialId = row.bonusMaterial ? row.bonusMaterial.id : null;
                    row.newKitName = value;
                    row.rangeOptions = !row.bonusMaterial ? [{
                      label: "No conseguido",
                      value: "No conseguido"
                    }] : [...row.bonusMaterial.ranges.map((range: BonusMaterialRange) => {
                      return {label: range.name, value: range.name}
                    }), {label: "No conseguido", value: "No conseguido"}];
                    updateRow(row);
                  }
                },
            );
          },
        },
        {
          title: "Cantidad producida",
          width: "100px",
          key: "amount",
          sorter: 'default',
          render(row: any) {
            return h(
                NInputNumber,
                {
                  defaultValue: Number(row.amount),
                  disabled: !row.refSelected,
                  onUpdateValue: (value: any) => {
                    row.newAmount = value;
                    row.enabledButton = true;
                    calculateNewRange(row.index, row.oldKitName, row.newKitName, row.newAmount);
                  }
                },
                {suffix: () => 'u.',}
            );
          },
        },
        {
          title: "Rango según cantidad",
          width: "70px",
          key: "rangeByAmount",
          sorter: 'default',
          render(row: any) {
            return h(
                NInput,
                {
                  defaultValue: row.rangeByAmount,
                  value: row.rangeByAmount,
                  placeholder: row.rangeByAmount,
                  // disabled: true,
                  readonly: true,
                  // newValue: row.rangeByAmount,
                },
            );
          },
        },
        {
          title: "Acciones",
          width: "70px",
          key: "actions",
          sorter: 'default',
          render(row: any) {
            return h(
                NButton,
                {
                  onClick: () => onSaveAll(row),
                  type: 'info',
                  strong: true,
                  secondary: true,
                  disabled: !row.enabledButton,
                },
                {
                  default: () => 'Guardar fila'
                }
            );
          },
        },
      ],
      columnOrder,
      isTablet,
      searchWord,
      type,
      todayDate,
      allUsers,
      sortTypeColor,
      selectedDay,
      shifts,
      getShift,
      onChangeShift,
      loadingStatus,
      onShowMenu,
    };
  }
})
